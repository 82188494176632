.table {
  .headerApplication{
    padding-block: 16px;
  }
  .applications{
    padding-block: 32px;
  }
}

.title-card{
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
  @media (min-width:1000px) {
    font-size: 22px;  
    font-weight: 500;
  }
  @media (min-width:1240px) {
    font-size: 4rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
}
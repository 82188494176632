//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}
.table-applications{
	border-spacing: 0px;
    border-collapse: separate;
    overflow: hidden;
    border-radius: 10px;
}
.border-transparent {
	--#{$prefix}border-opacity: 0;
}
.text-justify{
	text-align: justify !important;
}
.application-border-end{
	border-right: 2px solid #bbbdbe !important;
}
.application-border-bottom{
	border-bottom: 2px solid #bbbdbe !important;
}
.g-drop-search {
	position: relative;

	&--input {
		padding: 10px 15px;
		background-clip: padding-box;
    	border: 1px solid #f8f9fa;
		border-radius: 40px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #f8f9fa;
		box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
		font-size: 16px;

		.more {
			color: #b0b2b8;
		}
	}

	&--content {
		position: absolute;
		background: white;
		left: 0;
		right: 0;
		border: 1px solid #c8c8c8;
		border-radius: 4px;
		top: 41px;
		display: none;
		z-index: 2;

		.g-search {
			padding: 10px;
			border-bottom: 1px solid #c8c8c8;
		}

		.g-list-check {
			padding: 15px;
		}

		&.active {
			display: block;
		}
	}
}

.card .table .d-flex .avatar{
	overflow: hidden;
	width: 44px;
	border-radius: 50%;
	margin-right: 10px;
}

button{
	&.btn-cursor{
		border: 0;
		border-radius: 0;
		background-color: transparent;
		cursor: pointer;
	}
	&.btn-drag{
		border: 0;
		border-radius: 0;
		background-color: transparent;
		cursor: move;
	}
}

.rs-picker-menu {
	z-index: 1100 !important;
}

.input-group .dropdown-menu-end{
	right: 0 !important;
	left: auto !important;
	top: 40px !important;
	
}